/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
import { OrderDetailController } from './orderDetail-controller';
import { OrderSearchController } from './orderSearch-controller';
import { OrderGridController } from './orderGrid-controller';
var dependencies = ['comcast.common.authentication', 'comcast.common.communication', 'comcast.common.navigation', 'comcast.orders', 'ngMaterial', 'ngMessages', 'comcast.directives', 'ui.router'];
var defaultName = angular.module('comcast.orders.detail', dependencies).config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state('orderList', {
    // Specify params that this state can access from the URL
    url: '/list?orderName&poNumber&orderNumber&OrdersStatus&orderType&deliveryPriority&multiCallLetter&isci&receivedDateFrom&receivedDateTo&updatedDateFrom&updatedDateTo&agencyId&advertiserId&brandId&campaignId&estimateNumber&jobNumber&includeArchives&clearedFromDate&dateUpdated&dateReceived',
    parent: 'orders',
    // Specify default values for state params
    params: {
      orderName: null,
      orderNumber: null,
      orderType: null,
      multiCallLetter: null,
      isci: null,
      status: null,
      poNumber: null,
      updatedDate: null,
      advertiser: null,
      agency: null,
      brand: null,
      includeArchives: null,
      sortField: null,
      sortDirection: null,
      clearedFromDate: null
    },
    reloadOnSearch: true,
    views: {
      'orderContent@orders': {
        template: '<ui-view name="detailContent" class="detail-content"/>'
      },
      'orderSearch@orders': {
        template: '<ui-view name="detailSearch" class="detail-search" />'
      },
      'detailContent@orderList': {
        template: require('./orderGrid-template.html'),
        controller: OrderGridController,
        controllerAs: 'vm'
      },
      'detailSearch@orderList': {
        template: require('./orderSearch-template.html'),
        controller: OrderSearchController,
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  }).state('orderDetail', {
    url: '/orders/:id',
    parent: 'orderList',
    params: {
      gridData: null,
      pageData: null
    },
    views: {
      detailContent: {
        template: require('./orderDetail-template.html'),
        controller: OrderDetailController,
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  });
}]).name;
export { defaultName as default, dependencies };