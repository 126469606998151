/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2021 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

import * as bowser from 'bowser';
import * as moment from 'moment-timezone';

import { StatusConstant } from '../../constants/status.constant';
import { CopyOrderDialogController } from './copyOrderDialog-controller';
import { DynamicOrders } from '../common/DynamicItems/orders-dynamicitems';

//// CONTROLLER ////
export class OrderGridController {
    private statusConstants: any;
    private sessionData: any;

    public SearchParams: any = {};
    public dynamicItems: any;
    public orderStatuses: any;
    public statusService: any;
    public sortObj: any;
    public selectedOrders: any = [];
    public lastSelection: any = -1;
    public onlyArchivesSelected: boolean = true;
    public viewSelections: any;
    public viewUrls: any;
    public deliveryPriorities: any = [];
    public onlyDraftsSelected: boolean = true;
    public orderCostEstimateFlag: any;
    public orderMetadata: any = {};
    public isFranchise: any;
    public bowser: any;

    static get $inject() {
        return [
            '$mdDialog',
            '$q',
            '$scope',
            '$state',
            '$stateParams',
            '$timeout',
            '$window',
            'loginService',
            'OrderResourceFactory',
            'StatusService',
            'EnumService',
            'NotificationService',
            'EndPointService',
            'AuthenticationResourceFactory',
        ];
    }

    constructor(
        public $mdDialog: any,
        public $q: any,
        public $scope: any,
        public $state: any,
        public $stateParams: any,
        public $timeout: any,
        public $window: any,
        public loginService: any,
        public OrderResourceFactory: any,
        public StatusService: any,
        public EnumService: any,
        public NotificationService: any,
        public EndPointService: any,
        public AuthenticationResourceFactory: any
    ) {
        /* PRIVATE : DATA */
        //Declare all private variables here
        /*
            * This is the `vm` object. It is a direct reference to the controller
            * which acts as our 'view-model' in angular. It also limits our need
            * to be accessing $scope directly. */
        /*jshint validthis:true*/
        var vm = this;

        vm.statusConstants = new StatusConstant();
        vm.sessionData = loginService.getSessionData();

        /* BINDABLE : DATA */
        vm.orderStatuses = angular.copy(vm.statusConstants.orders);
        vm.statusService = StatusService;
        vm.sortObj = {
            field: $stateParams.sortField ? $stateParams.sortField : 'date_created',
            direction: $stateParams.sortDirection ? $stateParams.sortDirection : 'ASC',
        };
        vm.viewSelections = {
            deliveryConfirmationUrl: false,
            costEstimateReportUrl: false,
            receiptConfirmationReportUrl: false,
        };
        vm.viewUrls = {
            deliveryConfirmationUrl: false,
            costEstimateReportUrl: false,
            receiptConfirmationReportUrl: false,
        };
        vm.orderCostEstimateFlag = loginService.getSessionData().account.orderCostEstimateEnabledFlag;
        vm.isFranchise = vm.sessionData.franchiseCustomer;
        vm.bowser = bowser;

        /* EVENTS */
        //Register any event listeners
        vm.$scope.$on('$stateChangeSuccess', function (event: any, toState: any, toParams: any) {
            vm.SearchParams = toParams;
        });
    }

    $onInit() {
        let vm = this;

        vm.$timeout(function () {
            $('#orderListSearchOrderNumber').focus();
            vm.dynamicItems = new DynamicOrders(
                vm.SearchParams,
                vm.OrderResourceFactory,
                vm.StatusService,
                vm.loginService,
                vm.$timeout,
            );
        });
        $('.sidebar').removeClass('hidden');
        vm.$scope.home.showTgl = true;
        vm.EnumService.getEnum('DistributionOrder', 'deliveryPriority').then(
            function (priorities: any) {
                vm.deliveryPriorities = priorities;
            },
            function () {
                vm.deliveryPriorities = [];
            }
        );
    }

    /* IMPLEMENTATION : BINDABLE */
    sortOrders(field: any) {
        let vm = this;

        if (vm.sortObj.field === field) {
            vm.sortObj.direction = vm.sortObj.direction === 'ASC' ? 'DESC' : 'ASC';
        } else {
            vm.sortObj.field = field;
            vm.sortObj.direction = 'ASC';
        }
        vm.$state.go(
            'orderList',
            {
                sortField: vm.sortObj.field,
                sortDirection: vm.sortObj.direction,
            },
            { inherit: true }
        );
    }

    selectOrder(order: any, index: any, event: any) {
        let vm = this;

        if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
            vm.selectedOrders = [];
        }

        if (event.shiftKey && vm.lastSelection > -1) {
            // Do multiselect
            var toIndex = Math.max(index, vm.lastSelection);
            var fromIndex = Math.min(index, vm.lastSelection);

            for (let i = fromIndex; i <= toIndex; i++) {
                if (vm.selectedOrders.indexOf(i) < 0) {
                    vm.selectedOrders.push(i);
                }
            }
        } else if (vm.selectedOrders.indexOf(index) < 0) {
            vm.selectedOrders.push(index);
        } else {
            vm.selectedOrders.splice(vm.selectedOrders.indexOf(index), 1);
        }

        vm.onlyArchivesSelected = true;
        vm.onlyDraftsSelected = true;
        for (var orderIndex = 0; orderIndex < vm.selectedOrders.length; orderIndex++) {
            if (
                vm.dynamicItems.getItemAtIndex(vm.selectedOrders[orderIndex]).archived !==
                true
            ) {
                vm.onlyArchivesSelected = false;
            }
            if (
                vm.dynamicItems.getItemAtIndex(vm.selectedOrders[orderIndex])
                    .deliveryStatus !== 'DRAFT'
            ) {
                vm.onlyDraftsSelected = false;
            }
        }

        vm.lastSelection = index;
    }

    createNewOrder() {
        let vm = this;

        angular.element(document.querySelector('.sidebar') as HTMLElement).addClass('invisible');
        vm.$state.go('orderDetail', { id: '' }).then(function () {
            // Apply the animation ONLY when this transition happens
            vm.$timeout(function () {
                angular
                    .element(document.querySelector('.sidebar') as HTMLElement)
                    .removeClass('invisible');
                angular
                    .element(document.querySelector('.detail-list-area .side-grid') as HTMLElement)
                    .addClass('slide-left-transition');
            }, 0);
        });
    }

    openGridMenu($mdMenu: any, event: any, order: any) {
        let vm = this;

        vm.viewSelections = {
            deliveryConfirmationUrl: false,
            costEstimateReportUrl: false,
            receiptConfirmationReportUrl: false,
        };
        vm.getCostEstimateReport(order);
        vm.getDeliveryConfirmationReport(order);
        vm.getReceiptConfirmationReport(order);
        vm.getOrderCounts(order);
        $mdMenu.open(event);
    }

    openOrderDialog(order: any) {
        let vm = this;

        return vm.$mdDialog
            .show({
                controller: CopyOrderDialogController,
                controllerAs: 'vm',
                template: require('./copyOrderDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                fullscreen: true, // For small screens only
                locals: {
                    order,
                },
            })
            .then(
                function (options: any) {
                    vm._copyOrder(order, options);
                },
                function () {
                    //user cancelled the popup
                }
            );
    }

    getCostEstimateReport(order: any) {
        let vm = this;
        const accountName = vm.loginService.getSessionData().accountName;

        // Make request to get __sessionKey
        vm.AuthenticationResourceFactory.getSessionKey(
            {},
            function (sessionKey: any) {
                // Append __sessionKey to request to Node (instead of RE)
                vm.viewUrls.costEstimateReportUrl =
                    vm.EndPointService.orderCostEstimateEndPoint +
                    '?orderId=' +
                    order.id +
                    '&sessionKey=' +
                    sessionKey.data +
                    '&providerAccount=' + 
                    accountName;

                // NODE: Take __sessionKey and pipe request to RE
                // NODE: Open request directly into response to stream data back to browser
            },
            function () {
                // Unable to retrieve the session key, maybe the user is logged out.
            }
        );
        return true;
    }

    getDeliveryConfirmationReport(order: any) {
        let vm = this;

        // Make request to get __sessionKey
        vm.AuthenticationResourceFactory.getSessionKey(
            {},
            function (sessionKey: any) {
                // Append __sessionKey to request to Node (instead of RE)
                vm.viewUrls.deliveryConfirmationUrl =
                    vm.EndPointService.orderDeliveryConfirmationEndPoint +
                    '?orderId=' +
                    order.id +
                    '&sessionKey=' +
                    sessionKey.data;

                // NODE: Take __sessionKey and pipe request to RE
                // NODE: Open request directly into response to stream data back to browser
            },
            function () {
                // Unable to retrieve the session key, maybe the user is logged out.
            }
        );
        return true;
    }

    getReceiptConfirmationReport(order: any) {
        let vm = this;

        // Make request to get __sessionKey
        vm.AuthenticationResourceFactory.getSessionKey(
            {},
            function (sessionKey: any) {
                // Append __sessionKey to request to Node (instead of RE)
                vm.viewUrls.receiptConfirmationReportUrl =
                    vm.EndPointService.orderReceiptConfirmationEndPoint +
                    '?orderId=' +
                    order.id +
                    '&sessionKey=' +
                    sessionKey.data;

                // NODE: Take __sessionKey and pipe request to RE
                // NODE: Open request directly into response to stream data back to browser
            },
            function () {
                // Unable to retrieve the session key, maybe the user is logged out.
            }
        );
        return true;
    }

    getOrderCounts(order: any) {
        let vm = this;

        vm.OrderResourceFactory.getOrderCounts(
            { id: order.id },
            {},
            function success(response: any) {
                vm._calculateMetadata(response.data.orderGroups);
            },
            function failure(err: any) {
                console.log(err);
            }
        );
    }

    view() {
        let vm = this;
        vm._openReport(
            vm.viewUrls.costEstimateReportUrl,
            vm.viewSelections.costEstimateReportUrl
        );
        vm._openReport(
            vm.viewUrls.deliveryConfirmationUrl,
            vm.viewSelections.deliveryConfirmationUrl
        );
        vm._openReport(
            vm.viewUrls.receiptConfirmationReportUrl,
            vm.viewSelections.receiptConfirmationReportUrl
        );
    }

    parsePriority(priority: any) {
        let vm = this;
        if (vm.deliveryPriorities && vm.deliveryPriorities.length > 0) {
            return vm.EnumService.getEnumLabel(vm.deliveryPriorities, priority);
        } else {
            return '';
        }
    }

    openDetail(id: any) {
        let vm = this;

        angular.element(document.querySelector('.sidebar') as HTMLElement).addClass('invisible');
        vm.$state.go('orderDetail', { id }).then(function () {
            // Apply the animation ONLY when this transition happens
            vm.$timeout(function () {
                angular
                    .element(document.querySelector('.sidebar') as HTMLElement)
                    .removeClass('invisible');
                angular
                    .element(document.querySelector('.detail-list-area .side-grid') as HTMLElement)
                    .addClass('slide-left-transition');
            }, 0);
        });
    }

    archiveOrders($event: any) {
        let vm = this;
        let toArchive = false;

        for (let i = 0; i < vm.selectedOrders.length; i++) {
            if (vm.dynamicItems.getItemAtIndex(vm.selectedOrders[i]).archived === false) {
                toArchive = true;
            }
        }
        vm._archiveOrders(vm.selectedOrders, toArchive, $event);
    }

    deleteOrders($event: any) {
        let vm = this;
        let toDelete = false;

        for (let i = 0; i < vm.selectedOrders.length; i++) {
            if (
                vm.dynamicItems.getItemAtIndex(vm.selectedOrders[i]).deliveryStatus ===
                'DRAFT'
            ) {
                toDelete = true;
            }
        }
        vm._deleteOrders(vm.selectedOrders, toDelete, $event);
    }

    isSelected(rolesToValidate: any) {
        let vm = this;

        return (
            vm.selectedOrders.length < 1 ||
            !vm.loginService.hasPermission(rolesToValidate.split(','))
        );
    }

    verifyOrderStatus() {
        let vm = this;

        for (let i = 0; i < vm.selectedOrders.length; i++) {
            if (
                vm.dynamicItems.getItemAtIndex(vm.selectedOrders[i]).deliveryStatus ===
                'QUEUED' ||
                vm.dynamicItems.getItemAtIndex(vm.selectedOrders[i]).deliveryStatus ===
                'ACTIVE'
            ) {
                return false;
            }
        }
        return true;
    }

    disableView() {
        let vm = this;

        return (
            !vm.viewSelections.costEstimateReportUrl &&
            !vm.viewSelections.deliveryConfirmationUrl &&
            !vm.viewSelections.receiptConfirmationReportUrl
        );
    }

    displayTime(time: any) {
        return time
            ? moment
                .tz(time, 'YYYY-MM-DDTHH:mm:ss:SSS-ZZ', moment.tz.guess())
                .format('MMMM D, YYYY h:mm:ss A z')
            : '';
    }

    exportToCSV() {
        let vm = this;
        if (vm.dynamicItems.numItems > 2000) {
            vm.NotificationService.showNotificationToast(
                'Too many orders to export.  Please limit results to 2000 or less.'
            );
        } else {
            var sortField = vm.$stateParams.sortField
                ? vm.$stateParams.sortField
                : 'date_created';
            var sortDirection = vm.$stateParams.sortDirection
                ? vm.$stateParams.sortDirection
                : 'DESC';
            vm.SearchParams.sortField = sortField;
            vm.SearchParams.sortDirection = sortDirection;
            vm.SearchParams.accountName = vm.loginService.getSessionData().accountName;
            vm.OrderResourceFactory.getOrderMetadata(vm.SearchParams, {}, function (csv: any) {
                let blob: any = new Blob([csv.data], {
                    type: csv.config['Content-Type'],
                });

                if (
                    (vm.bowser.msie || vm.bowser.msedge) &&
                    vm.$window.navigator &&
                    vm.$window.navigator.msSaveBlob
                ) {
                    vm.$window.navigator.msSaveBlob(blob, 'orders.csv');
                } else {
                    var url = URL.createObjectURL(blob);
                    var downloadLink = document.createElement('A') as any;
                    downloadLink.href = url;
                    downloadLink.download = 'orders.csv';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }
                URL.revokeObjectURL(blob);
            });
        }
    }

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them
    _openReport(url: any, selected: any) {
        let vm = this;

        if (selected) {
            vm.$window.open(url, '_blank');
        }
    }

    _archiveOrders(orders: any, flag: any, ev: any) {
        let vm = this;

        // Only run the command on actionable spots
        var filteredOrders = orders.filter(function (cur: any) {
            return vm.dynamicItems.getItemAtIndex(cur).archived !== flag;
        });
        var confirmOrNot;

        if (flag) {
            var confirm = vm.$mdDialog
                .confirm()
                .title('Would you like to continue?')
                .textContent(
                    "Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'."
                )
                .ariaLabel(
                    "Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'."
                )
                .targetEvent(ev)
                .ok('Continue')
                .cancel('Cancel');

            confirmOrNot = vm.$mdDialog.show(confirm);
        } else {
            confirmOrNot = vm.$q.when(true);
        }

        confirmOrNot.then(
            function () {
                // User confirmed the archive function
                // Set the flag to show whether or not there are any archived orders selected (since we're making them all the same with this function)
                vm.onlyArchivesSelected = flag;
                filteredOrders = filteredOrders.map(function (cur: any) {
                    //Have to archive the Orders on both views
                    var order = vm.dynamicItems.getItemAtIndex(cur);
                    order.archived = flag;
                    return order;
                });

                vm.$q.all(
                    filteredOrders.map(function (cur: any) {
                        return vm.OrderResourceFactory.save({ id: cur.id }, cur);
                    })
                ).then(
                    function success() {
                        // Orders successfully archived
                    },
                    function failure() {
                        // Orders Failed to be Archived
                    }
                );
            },
            function () {
                // User cancelled the archive function
            }
        );
    };

    _deleteOrders(orders: any, flag: any, ev: any) {
        let vm = this;
        let confirmOrNot;

        if (flag) {
            var confirm = vm.$mdDialog
                .confirm()
                .title('Delete Order')
                .textContent('Are you sure you want to delete?')
                .ariaLabel('Are you sure you want to delete?')
                .targetEvent(ev)
                .ok('Delete')
                .cancel('Cancel');

            confirmOrNot = vm.$mdDialog.show(confirm);
        } else {
            confirmOrNot = vm.$q.when(true);
        }

        confirmOrNot.then(
            function () {
                // User confirmed the delete function
                vm.onlyDraftsSelected = flag;
                vm.$q.all(
                    orders.map(function (cur: any) {
                        return vm._deleteOrder(vm.dynamicItems.getItemAtIndex(cur)).$promise;
                        // return orderPromise;
                    })
                ).then(
                    () => {
                        //orders successfully deleted
                        vm.NotificationService.showNotificationToast(
                            'Order successfully deleted'
                        );
                        vm.$state.reload();
                    },
                    (failure: any) => {
                        //orders failed to be deleted
                        vm.NotificationService.showNotificationToast(
                            'Could not delete order',
                            failure.data
                        );
                    }
                );
            },
            function () {
                // User cancelled the delete function
            }
        );
    };

    _deleteOrder(order: any) {
        let vm = this;

        return vm.OrderResourceFactory.deleteOrder(
            { id: order.id },
            () => {
                //individual order deletion succeeded
            },
            () => {
                //individual order failed to be deleted
            }
        );
    }

    _copyOrder(order: any, options: any) {
        let vm = this;

        vm.OrderResourceFactory.copyOrder(
            {
                id: order.id,
                brandId: order.brandId,
                advertiserId: order.advertiserId,
                poNumber: order.poNumber,
                notifyEmailAddresses: options.copyEmailNotifications
                    ? order.notifyEmailAddresses
                    : '',
                agencyId: order.agencyId,
                options,
            },
            function (newOrder: any) {
                vm.OrderResourceFactory.get(
                    { id: newOrder.data },
                    function success(hydratedOrder: any) {
                        hydratedOrder.notifyEmailAddresses = options.copyEmailNotifications
                            ? order.notifyEmailAddresses
                            : '';
                        hydratedOrder.confirmThroughEcnFlag = null;
                        hydratedOrder.deliverThroughEcnFlag = null;
                        vm.OrderResourceFactory.save(
                            { id: hydratedOrder.id },
                            hydratedOrder,
                            function success() {
                                vm.NotificationService.showNotificationToast(
                                    'Order ' + order.id + ' successfully copied'
                                );
                                vm.$state.reload();
                            },
                            function failure(err: any) {
                                console.log(err);
                            }
                        );
                    },
                    function failure(err: any) {
                        console.log(err);
                    }
                );
            },
            function (failure: any) {
                vm.NotificationService.showNotificationToast('Order copy failed', failure);
            }
        );
    }

    _calculateMetadata(data: any) {
        let vm = this;

        vm.orderMetadata.spotsCount = 0;
        vm.orderMetadata.groupCount = data.length;
        vm.orderMetadata.destinationCount = 0;
        vm._calculateDestsAndSpots(data);
    }

    _calculateDestsAndSpots(orderGroups: any) {
        let vm = this;
        let OGDests = [];
        let OGSpots = [];
        for (let i = 0; i < orderGroups.length; i++) {
            for (var j = 0; j < orderGroups[i].OGDestinations.length; j++) {
                OGDests.push(orderGroups[i].OGDestinations[j].destination_id);
            }
            for (var k = 0; k < orderGroups[i].OGSpots.length; k++) {
                OGSpots.push(orderGroups[i].OGSpots[k].spot_id);
            }
        }
        vm.orderMetadata.destinationCount = vm._filterUniques(OGDests);
        vm.orderMetadata.spotsCount = vm._filterUniques(OGSpots);
    }

    _filterUniques(data: any) {
        return data.filter(function (value: any, index: any) {
            return data.indexOf(value) == index;
        }).length;
    }
}
