/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */


import { CopyOrderDialogController } from "./copyOrderDialog-controller";

declare var angular: angular.IAngularStatic;

import * as Bowser from 'bowser';
import * as moment from 'moment';
import * as Flow from '@flowjs/flow.js';
import * as $ from "jquery";

import { UnavailableDestinationsDialogController } from './dialogs/unavailableDestinationsDialog-controller';
import { EmailNotificationsDialogController } from './dialogs/emailNotificationsDialog-controller';
import { saveBeforeExportDialogController } from "./dialogs/saveBeforeExportDialog-controller";
import { SubmitLaterDialogController } from './dialogs/submitLaterDialog-controller';
import { RemoveOrderGroupDialogController } from './dialogs/removeOrderGroupDialog-controller';
import { OrderImportErrorDialogController } from './dialogs/orderImportErrorDialog-controller';
import { OptimizeDialogController } from './dialogs/optimizeDialog-controller';
import { EcnDialogController } from './dialogs/ecnDialog-controller';
import { VastXmlDialogController } from './dialogs/vastXmlDialog-controller';
import { DeliveryPriorityDialogController } from './dialogs/deliveryPriorityDialog-controller';
import { StatusConstant } from '../../constants/status.constant';
import { AuthConstant } from '../../constants/auth.constant';
import { DynamicOrderItems } from "./orderItems-dynamicItems";

export class OrderDetailController {
    // Between you and me
    private orderGroupPromise: any;

    //Bindable
    public dynamicOrderItemsArray: any;
    public statusService: any;
    public orderStatuses: any;
    public deliveryStatuses: any;
    public orderTypes: any;
    public deliveryPriorities: any;
    public order: any;
    public originalOrder: any;
    public displayError: any;
    public sessionData: any;
    public isFranchise: any;
    public groups: any;
    public spotThumbs: any;
    public destinationStatuses: any;
    public new: any;
    public copying: any;
    public hasNote: any;
    public loadingGroup: any;
    public importError: any;
    public accountCampaigns: any;
    public processing: any;
    public displayListView: any;
    public showDetailPanel: any;
    public selectedGroup: any;
    public bowser: any;
    public authConstants: any;
    public fileToUpload: any;
    public today: Date = new Date(); // Used in the template
    public searchAgency: string = '';
    public searchAdvertiser: string = '';
    public searchBrand: string = '';
    public searchCampaign: string = '';

    static get $inject() {
        return [
            '$mdDialog',
            '$q',
            '$rootScope',
            '$scope',
            '$state',
            '$stateParams',
            '$timeout',
            '$window',
            'AdvertiserResourceFactory',
            'AuthenticationResourceFactory',
            'AgencyResourceFactory',
            'AssetResourceFactory',
            'BrandsResourceFactory',
            'CampaignsResourceFactory',
            'DestinationResourceFactory',
            'EndPointService',
            'EnumService',
            'loginService',
            'NotificationService',
            'OrderResourceFactory',
            '$filter',
            'addelivery.event.constants',
            'StatusService',
        ];
    }

    constructor(
        public $mdDialog: any,
        public $q: any,
        public $rootScope: any,
        public $scope: any,
        public $state: any,
        public $stateParams: any,
        public $timeout: any,
        public $window: any,
        public AdvertiserResourceFactory: any,
        public AuthenticationResourceFactory: any,
        public AgencyResourceFactory: any,
        public AssetResourceFactory: any,
        public BrandsResourceFactory: any,
        public CampaignsResourceFactory: any,
        public DestinationResourceFactory: any,
        public EndPointService: any,
        public EnumService: any,
        public loginService: any,
        public NotificationService: any,
        public OrderResourceFactory: any,
        public $filter: any,
        public EventConstants: any,
        public StatusService: any,
    ) {
        let vm = this;

        // Private promise holders
        vm.orderGroupPromise = $q.defer();

        let statusConstants = new StatusConstant();

        /* BINDABLE : DATA */
        vm.dynamicOrderItemsArray = {};
        vm.statusService = StatusService;
        vm.orderStatuses = angular.copy(statusConstants.orders);
        vm.deliveryStatuses = angular.copy(statusConstants.deliveries);
        vm.orderTypes = [];
        vm.deliveryPriorities = [];
        vm.originalOrder = {};
        vm.displayError = false;
        vm.loginService = loginService;
        vm.sessionData = loginService.getSessionData();
        vm.isFranchise = vm.sessionData.franchiseCustomer;
        vm.groups = {};
        vm.spotThumbs = {};
        vm.destinationStatuses = {};
        vm.new = false;
        vm.copying = false;
        vm.hasNote = false;
        vm.loadingGroup = false;
        vm.importError = false;
        vm.accountCampaigns = [];
        vm.processing = false;
        vm.displayListView = true;
        vm.showDetailPanel = false;
        vm.selectedGroup = null;
        vm.bowser = Bowser;
        vm.authConstants = new AuthConstant();

        /* EVENTS */
        let cancelSizing = $scope.$watch(
            function () {
                let testHeader = document.getElementsByClassName('sizable-header')[0];

                if (
                    testHeader &&
                    angular.element(testHeader) &&
                    angular.element(testHeader)[0]
                ) {
                    return angular.element(testHeader)[0].clientWidth;
                } else {
                    return 0;
                }
            },
            function () {
                $timeout(function () {
                    // Set the flex-basis on all resizable headers
                    let headers = document.getElementsByClassName('sizable-header');

                    for (let i = 0; i < headers.length; i++) {
                        if (!vm.bowser.msie) {
                            angular
                                .element(headers[i])
                                .css(
                                    'flex-basis',
                                    angular.element(headers[i])[0].clientWidth
                                );
                        } else {
                            angular
                                .element(headers[i])
                                .css('width', angular.element(headers[i])[0].clientWidth);
                        }
                    }
                });
            },
            true
        );

        $scope.$on('angular-resizable.resizing', function () {
            // Set the flex-basis on all resizable headers
            let headers = document.getElementsByClassName('sizable-header');

            for (let i = 0; i < headers.length; i++) {
                if (!vm.bowser.msie) {
                    angular
                        .element(headers[i])
                        .css('flex-basis', angular.element(headers[i])[0].clientWidth);
                } else {
                    angular
                        .element(headers[i])
                        .css('width', angular.element(headers[i])[0].clientWidth);
                }
            }

            if (cancelSizing) {
                cancelSizing();
                cancelSizing = null;
            }
        });
    }

    $onInit() {
        let vm = this;

        $('.sidebar').addClass('hidden');

        vm.$scope.home.showTgl = false;
        vm.$timeout(function () {
            vm.$rootScope.viewDetailForm = vm.$scope.viewDetailForm;
        });
        vm._loadData();
    }

    /* IMPLEMENTATION : BINDABLE */
    checkStatus(orderStatuses: any, included: any) {
        let vm = this;
        let retValue = true;
        // default to included = true if not passed in
        included = included === undefined ? true : included;
        // if included is true, check to see if statuses are IN the passed-in array.
        // if false, check to see if statuses are NOT in the passed-in array.
        if (
            orderStatuses &&
            orderStatuses.length > 0 &&
            vm.order &&
            vm.order.deliveryStatus
        ) {
            retValue = included
                ? orderStatuses.indexOf(vm.order.deliveryStatus) >= 0
                : orderStatuses.indexOf(vm.order.deliveryStatus) < 0;
        }
        return retValue;
    }

    notNewAndDraft(rolesToCheck: any) {
        let vm = this;
        let hasPerm = true;
        if (rolesToCheck && rolesToCheck.length > 0) {
            hasPerm = vm.loginService.hasPermission(rolesToCheck);
        }
        // always disable it if they don't have the right role
        return (!vm.new && vm.checkStatus(['DRAFT'], false)) || !hasPerm;
    }

    loadAdvertisers(nameSearch: string) {
        let vm = this;

        return new Promise((resolve, reject) => {
            let params = {
                providerAccountId: vm.order.providerAccountId,
                active: true,
                agencyId: -1,
                name: nameSearch,
                limit: 15,
            };
            if (vm.order && vm.order.Agency && vm.order.Agency.id !== '') {
                params.agencyId = vm.order.Agency.id;
            }

            vm.AdvertiserResourceFactory.getAll(
                params,
            ).subscribe(
                (advertisers: any) => {
                    let found = advertisers;
                    //If necessary, filtering to only advertisers with a blank Agency
                    if (
                        (!vm.order.Agency ||
                            !vm.order.Agency.id ||
                            vm.order.Agency.id === '') &&
                        !vm.sessionData.account.adStorFlag &&
                        !vm.order.Advertiser
                    ) {
                        found = vm.$filter('filter')(found, {
                            agencyId: null,
                        });
                    }
                    resolve(found);
                },
                (err: any) => {
                    resolve([]);
                    console.debug(err);
                }
            );
        });
    }

    loadAgencies(nameSearch: string) {
        let vm = this;

        return new Promise((resolve, reject) => {
            vm.AgencyResourceFactory.getAll(
                {
                    providerAccountId: vm.order.providerAccountId,
                    name: nameSearch,
                    activeOnly: true,
                    limit: 15,
                }).subscribe(
                    (agencies: any) => {
                        resolve(agencies.rows);
                    },
                    (err: any) => {
                        resolve([]);
                        console.debug(err);
                    }
                );
        });
    }

    loadBrands(nameSearch: string) {
        let vm = this;

        return new Promise((resolve, reject) => {
            // If there is no advertiser, then there is nothing to do here.
            if (!vm.order || !vm.order.Advertiser || vm.order.Advertiser.id === '') {
                resolve([]);
            }

            // Search by the provider account and search term for sure
            let params = {
                providerAccountId: vm.order.providerAccountId,
                advertiserId: vm.order.Advertiser.id,
                name: nameSearch,
                active: true,
                limit: 15,
            };

            vm.BrandsResourceFactory.getAll(
                params
            ).subscribe(
                (brands: any) => {
                    resolve(brands);
                },
                (err: any) => {
                    resolve([]);
                    console.debug(err);
                }
            );
        });
    }

    loadCampaigns(nameSearch: string) {
        let vm = this;

        return new Promise((resolve, reject) => {
            if (!vm.order.Brand || !vm.order.Brand.id) {
                resolve([]);
            }

            vm.CampaignsResourceFactory.getAll(
                {
                    accountId: vm.order.providerAccountId,
                    brandId: vm.order.Brand.id,
                    name: nameSearch,
                    active: true,
                    limit: 15,
                }
            ).subscribe(
                (campaigns: any) => {
                    let found = campaigns;
                    // append these to the campaign drop down
                    found = vm.$filter('filter')(
                        found,
                        { brandId: '' },
                        false
                    );
                    if (found.length === 0) {
                        found = angular.copy(vm.accountCampaigns);
                    }
                    resolve(found);
                },
                (err: any) => {
                    resolve([]);
                    console.debug(err);
                }
            );
        });
    }

    loadOrderItems(group: any) {
        let vm = this;

        vm.showDetailPanel = true;
        vm.selectedGroup = group;
        if (vm.dynamicOrderItemsArray && !vm.dynamicOrderItemsArray[group.id]) {
            // set up the infinite scroll controller for this group.
            vm.dynamicOrderItemsArray[group.id] = new DynamicOrderItems(
                vm.$stateParams,
                vm.$timeout,
                vm.AssetResourceFactory,
                vm.OrderResourceFactory,
                vm.StatusService,
                vm.DestinationResourceFactory,
                group.id);
        }
    }

    loadOrderGroup(id: number, loadImages: boolean) {
        let vm = this;

        // default to true if not passed in
        loadImages = loadImages === undefined ? true : loadImages;
        let groupPromise = vm.$q.defer();

        if (id && (vm.groups[id] === undefined || !vm.groups[id])) {
            vm.loadingGroup = true;
            vm.OrderResourceFactory.getOrderGroup(
                { id },
                function (group: any) {
                    vm.loadingGroup = false;
                    vm.groups[id] = group.data;
                    vm.groups[id].name = vm.groups[id].name.replace(/&amp;/g, '&');
                    if (loadImages) {
                        // load the spot thumbs
                        for (let i = 0; i < vm.groups[id].OGSpots.length; i++) {
                            if (
                                vm.groups[id].OGSpots[i].Spot &&
                                vm.groups[id].OGSpots[i].Spot.Storyboard[0] &&
                                vm.groups[id].OGSpots[i].Spot.Storyboard[0].Asset
                            ) {
                                // We only ever retrieve one thumbnail, so 0 is okay here
                                vm._getThumbnail(
                                    vm.groups[id].OGSpots[i].Spot.isci,
                                    vm.groups[id].OGSpots[i].Spot.Storyboard[0].Asset
                                        .AssetContent.contentUuid
                                );
                            }
                        }
                        // load the destination statuses
                        for (let j = 0; j < vm.groups[id].OGDestinations.length; j++) {
                            if (vm.groups[id].OGDestinations[j].Destination) {
                                vm._getDestinationStatus(
                                    vm.groups[id].OGDestinations[j].Destination
                                        .distributionType,
                                    vm.groups[id].OGDestinations[j].Destination
                                        .ReceiverAccount.id,
                                    vm.groups[id].OGDestinations[j].Destination.id
                                );
                            }
                        }
                    }
                    groupPromise.resolve(group);
                },
                function (error: any) {
                    vm.loadingGroup = false;
                    groupPromise.reject(error);
                    vm.NotificationService.showNotificationToast(
                        'Order Group failed to load',
                        error.data
                    );
                }
            );
        } else {
            groupPromise.resolve(vm.groups[id]);
        }

        return groupPromise;
    }

    addOrderGroup(tabIndex: number) {
        let vm = this;

        if (
            !(
                vm.order.orderName &&
                vm.order.Advertiser.id &&
                vm.order.Brand.id &&
                vm.order.poNumber
            )
        ) {
            let confirm = vm.$mdDialog
                .confirm()
                .title('Incomplete Order')
                .textContent(
                    'Please enter an order name, advertiser name, brand, and PO number before adding a group.'
                )
                .ariaLabel(
                    'Please enter an order name, advertiser name, brand, and PO number before adding a group.'
                )
                .ok('OK');

            vm.$mdDialog.show(confirm);
        } else {
            let newGroup = {
                id: -1,
                orderId: vm.order.id,
                OGSpots: [],
                OGDestinations: [],
                OrderGroupTraffic: [],
                sequence: vm.order.orderGroups ? vm.order.orderGroups.length + 1 : 1,
            };
            vm.$mdDialog
                .show({
                    controller: 'orderGroupDialogController',
                    controllerAs: 'vm',
                    template: require('./orderGroupDialog-template.html'),
                    parent: angular.element(document.body),
                    clickOutsideToClose: false,
                    fullscreen: true, // For small screens only
                    multiple: true,
                    locals: {
                        orderType: vm.order.orderType,
                        group: newGroup,
                        allGroups: vm.order.orderGroups,
                        selectedTabIndex: tabIndex,
                        previousSpots: [],
                        previousDests: [],
                    },
                })
                .then(
                    function (saved: any) {
                        // Update returned values
                        if (saved.groupIds) {
                            // Don't want to duplicate existing order groups on the page, so update ones that exist and add the one that doesn't
                            // This is so that you can click "Save & Add to All Groups" on new OrderGroups and everything works
                            let existingGroupIds = vm.order.orderGroups.map(function (
                                group: any
                            ) {
                                return parseInt(group.id, 10);
                            });

                            let allNewIdsForTraffic = [];
                            let allUpdatedIdsForTraffic = [];
                            let loadOrderGroupRequests = [];

                            for (let id = 0; id < saved.groupIds.length; id++) {
                                if (
                                    vm.order.orderGroups &&
                                    saved.groupIds.hasOwnProperty(id) &&
                                    existingGroupIds.indexOf(saved.groupIds[id]) > -1
                                ) {
                                    delete vm.groups[saved.groupIds[id]];

                                    allUpdatedIdsForTraffic.push(saved.groupIds[id]);
                                    // refetch group so we have all the proper data from the RE save
                                    loadOrderGroupRequests.push(
                                        vm.loadOrderGroup(saved.groupIds[id], false)
                                            .promise
                                    );
                                } else {
                                    vm.loadOrderGroup(
                                        saved.groupIds[id],
                                        false
                                    ).promise.then(function (updatedGroup: any) {
                                        vm.order.orderGroups.push(
                                            vm.groups[
                                            parseInt(updatedGroup.data.id, 10)
                                            ]
                                        );
                                        allNewIdsForTraffic.push(updatedGroup.data.id);
                                    });
                                }
                            }

                            if (loadOrderGroupRequests.length > 0) {
                                vm._refreshGroups(loadOrderGroupRequests);
                            }
                        }
                    },
                    function cancelled() {
                        // Do nothing, the user cancelled the dialog
                    }
                );
        }
    }

    editOrderGroup(groupId: any, tabIndex: any) {
        let vm = this;

        vm.loadOrderGroup(groupId, false).promise.then(
            function () {
                return vm.$mdDialog
                    .show({
                        controller: 'orderGroupDialogController',
                        controllerAs: 'vm',
                        template: require('./orderGroupDialog-template.html'),
                        parent: angular.element(document.body),
                        clickOutsideToClose: false,
                        fullscreen: true, // For small screens only
                        locals: {
                            orderType: vm.order.orderType,
                            group: angular.copy(vm.groups[groupId]),
                            allGroups: vm.order.orderGroups,
                            selectedTabIndex: tabIndex,
                            previousSpots: vm.groups[groupId].OGSpots,
                            previousDests: vm.groups[groupId].OGDestinations,
                        },
                    })
                    .then(
                        function (saved: any) {
                            if (saved.groupIds) {
                                let loadOrderGroupRequests = [];

                                for (let id = 0; id < saved.groupIds.length; id++) {
                                    // remove our previously loaded group to force a reload of data
                                    if (vm.groups) {
                                        delete vm.groups[saved.groupIds[id]];
                                    }

                                    // refetch group so we have all the proper data from the RE save
                                    loadOrderGroupRequests.push(
                                        vm.loadOrderGroup(saved.groupIds[id], false)
                                            .promise
                                    );
                                }

                                if (loadOrderGroupRequests.length > 0) {
                                    vm._refreshGroups(loadOrderGroupRequests);
                                }
                            }
                        },
                        function cancelled(response: any) {
                            //Check if the user removed anything
                            let destsRemoved = response.itemsRemoved.destinations;
                            let spotsRemoved = response.itemsRemoved.spots;
                            if (destsRemoved.length > 0) {
                                for (let i = 0; i < destsRemoved.length; i++) {
                                    let tempIndex = vm.groups[
                                        response.itemsRemoved.id
                                    ].OGDestinations.indexOf(destsRemoved[i]);
                                    vm.groups[
                                        response.itemsRemoved.id
                                    ].OGDestinations.splice(tempIndex, 1);
                                }
                            }
                            if (spotsRemoved.length > 0) {
                                for (let i = 0; i < spotsRemoved.length; i++) {
                                    let tempIndex = vm.groups[
                                        response.itemsRemoved.id
                                    ].OGSpots.indexOf(destsRemoved[i]);
                                    vm.groups[response.itemsRemoved.id].OGSpots.splice(
                                        tempIndex,
                                        1
                                    );
                                }
                            }
                        }
                    );
            },
            function (error: any) {
                // we already showed an error, don't do it again
            }
        );
    }

    removeSpot(groupSpotObject: any, groupId: any) {
        let vm = this;

        if (vm.groups[groupId]) {
            vm.OrderResourceFactory.removeOrderGroupItem(
                {
                    id: vm.order.id,
                    groupId,
                    OGSpots: [groupSpotObject.id],
                },
                function success() {
                    let index = vm.groups[groupId].OGSpots.indexOf(groupSpotObject);
                    if (index >= 0) {
                        vm.groups[groupId].OGSpots.splice(index, 1);
                    }
                },
                function failure(reason: any) {
                    vm.NotificationService.showNotificationToast(
                        'Failed to remove spot from order group',
                        reason.data
                    );
                }
            );
        }
    }

    removeDestination(groupdestinationObject: any, groupId: any) {
        let vm = this;

        if (vm.groups[groupId]) {
            vm.OrderResourceFactory.removeOrderGroupItem(
                {
                    id: vm.order.id,
                    groupId,
                    OGDestinations: [groupdestinationObject.id],
                },
                function success() {
                    let index = vm.groups[groupId].OGDestinations.indexOf(
                        groupdestinationObject
                    );
                    if (index >= 0) {
                        vm.groups[groupId].OGDestinations.splice(index, 1);
                    }
                },
                function failure(reason: any) {
                    vm.NotificationService.showNotificationToast(
                        'Failed to remove destination from order group',
                        reason.data
                    );
                }
            );
        }
    }

    removeTraffic(grouptrafficObject: any, groupId: any) {
        let vm = this;

        if (vm.groups[groupId]) {
            vm.OrderResourceFactory.removeOrderGroupItem(
                {
                    id: vm.order.id,
                    groupId,
                    OrderGroupTraffic: [grouptrafficObject.trafficAssetId],
                },
                function success() {
                    let index = vm.groups[groupId].OrderGroupTraffic.indexOf(
                        grouptrafficObject
                    );
                    if (index >= 0) {
                        vm.groups[groupId].OrderGroupTraffic.splice(index, 1);
                    }

                    // eslint-disable-next-line
                    vm.order.orderGroups.map((orderGroup: any, key: any) => {
                        if (orderGroup.id === groupId) {
                            vm.order.orderGroups[key].trafficNames = [];
                            vm.order.orderGroups[key].loadedTraffic = false;
                            vm.order.orderGroups[key].OrderGroupTrafficCount = vm.order
                                .orderGroups[key].OrderGroupTrafficCount
                                ? vm.order.orderGroups[key].OrderGroupTrafficCount - 1
                                : vm.groups[groupId].OrderGroupTraffic.length;
                            return orderGroup;
                        }
                    });
                },
                function failure(reason: any) {
                    vm.NotificationService.showNotificationToast(
                        'Failed to remove traffic from order group',
                        reason.data
                    );
                }
            );
        }
    }

    changeAgency() {
        let vm = this;

        if (
            vm.order.Agency === null ||
            (
                vm.order.Advertiser &&
                vm.order.Advertiser.agencyId !== vm.order.Agency.id
            )
        ) {
            vm.order.Advertiser = null;
            vm.changeAdvertiser();
        }
    }

    changeAdvertiser() {
        let vm = this;

        // If an advertiser with no ID is selected somehow, just blow it away
        if (vm.order.Advertiser && vm.order.Advertiser.id === '') {
            vm.order.Advertiser = null;
        }

        // If the advertiser has been cleared, or if the brand no longer matches, clear the brand out
        if (
            vm.order.Advertiser === null ||
            (
                vm.order.Brand &&
                vm.order.Brand.advertiserId !== (vm.order.Advertiser && vm.order.Advertiser.id ? vm.order.Advertiser.id : null)
            )
        ) {
            vm.order.Brand = null;
            vm.changeBrand();
        }
    }

    changeBrand() {
        let vm = this;

        // If a bad brand is selected somehow, clear it out
        if (vm.order.Brand && vm.order.Brand.id === '') {
            vm.order.Brand = null;
        }

        if (
            vm.order.Brand === null ||
            (
                vm.order.Campaign &&
                vm.order.Campaign.brandId !== (vm.order.Brand && vm.order.Brand.id ? vm.order.Brand.id : null)
            )
        ) {
            vm.order.Campaign = null;
        }
    }

    // When a user moves away from an autocomplete, we need to ensure they selected a valid option
    checkValidSelection(type: string) {
        let vm = this;

        let search = '';
        let desired = {
            name: ''
        };

        switch (type) {
            case 'agency':
                search = vm.searchAgency;
                desired = vm.order.Agency;
                break;
            case 'advertiser':
                search = vm.searchAdvertiser;
                desired = vm.order.Advertiser;
                break;
            case 'brand':
                search = vm.searchBrand;
                desired = vm.order.Brand;
                break;
            case 'campaign':
                search = vm.searchCampaign;
                desired = vm.order.Campaign;
                break;
        }

        // If search was cleared, that's fine, we've nothing to do then
        if (search === '') {
            return;
        }

        if (!desired || desired.name !== search) {
            switch (type) {
                case 'agency':
                    vm.searchAgency = '';
                    vm.order.Agency = null;
                    break;
                case 'advertiser':
                    vm.searchAdvertiser = '';
                    vm.order.Advertiser = null;
                    break;
                case 'brand':
                    vm.searchBrand = '';
                    vm.order.Brand = null;
                    break;
                case 'campaign':
                    vm.searchCampaign = '';
                    vm.order.Campaign = null;
                    break;
            }
        }
    }

    openSystemEvents() {
        let vm = this;

        if (vm.order && vm.order.id) {
            vm.$mdDialog.show({
                controller: 'SystemEventsDialogController',
                controllerAs: 'vm',
                template: require('../common/dialogs/systemEventsDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                fullscreen: true,
                locals: {
                    id: vm.order.id,
                    type: 'distributionOrder',
                },
            });
        }
    }

    cancel() {
        let vm = this;

        vm.$state.go('orderList', {}, { notify: true });
    }

    cancelOrder() {
        let vm = this;
        if((vm.order.orderType === 'RADIO' && vm.order.SecurityAccount.tapOrderRadioFlag) || (vm.order.orderType === 'TV' && vm.order.SecurityAccount.tapOrderTVFlag)) {
            vm.OrderResourceFactory.cancelTap(
                { orderId: vm.order.id },
                function (success: any) {
                    vm.NotificationService.showNotificationToast(
                        'Order ' + vm.order.id + ' successfully cancelled'
                    );
                    vm.$state.reload();
                },
                function (failure: any) {
                    vm.NotificationService.showNotificationToast(
                        'Could not cancel order',
                        failure
                    );
                }
            )
        } else {
            vm.OrderResourceFactory.cancel(
                { orders: [{ id: vm.order.id, orderType: vm.order.orderType, tapOrderRadioFlag: vm.order.SecurityAccount.tapOrderRadioFlag }] },
                function (success: any) {
                    if (success.failure.length > 0) {
                        try {
                            vm.NotificationService.showNotificationToast(
                                'Could not cancel order',
                                success.failure[0].technical.response.$.message
                            );
                        } catch (e) {
                            vm.NotificationService.showNotificationToast(
                                'Could not cancel order',
                                'Server Error'
                            );
                        }
                    } else {
                        vm.NotificationService.showNotificationToast(
                            'Order ' + vm.order.id + ' successfully cancelled'
                        );
                    }
                    vm.$state.reload();
                },
                function (failure: any) {
                    vm.NotificationService.showNotificationToast(
                        'Could not cancel order',
                        failure
                    );
                }
            );
        }
    }

    deleteOrder() {
        let vm = this;

        if (vm.order) {
            let confirmOrNot;

            let confirm = vm.$mdDialog
                .confirm()
                .title('Delete Order')
                .textContent('Delete the current item?')
                .ariaLabel('Delete the current item?')
                .ok('Delete')
                .cancel('Cancel');
            confirmOrNot = vm.$mdDialog.show(confirm);

            confirmOrNot.then(
                function () {
                    vm.OrderResourceFactory.delete(
                        { id: vm.order.id },
                        function (success: any) {
                            vm.NotificationService.showNotificationToast(
                                'Order ' + vm.order.id + ' successfully deleted'
                            );
                            vm.$state.go('orderList', {}, { notify: true });
                        },
                        function (failure: any) {
                            vm.NotificationService.showNotificationToast(
                                'Could not delete order',
                                failure.data
                            );
                        }
                    );
                },
                function () {
                    // User cancelled the archive function so don't do anything
                }
            );
        }
    }

    noBubble(ev: any): void {
        ev.stopPropagation();
    }

    resetOrderDetails() {
        let vm = this;

        // Clear out typeaheads
        vm.searchAgency = '';
        vm.searchAdvertiser = '';
        vm.searchBrand = '';
        vm.searchCampaign = '';

        // Reset the titles of the collapses
        vm.groups = {};
        // Reset all the other things
        vm._loadData();
        // ...
        // Profit
        vm.$scope.orderDetailForm.$setPristine();
    }

    saveAndAddAnother() {
        let vm = this;

        vm.save(true, false);
    }

    save(loadNew: boolean, notify: boolean) {
        let vm = this;

        loadNew = loadNew === undefined ? false : loadNew;
        notify = notify === undefined ? true : notify;

        vm.$scope.orderDetailForm.$setPristine();

        return vm._doSave(loadNew, notify).$promise;
    }

    disableSave() {
        let vm = this;

        let origOrder = angular.copy(vm.originalOrder);
        if (origOrder && origOrder.orderGroups) {
            delete origOrder.orderGroups;
        }

        let newOrder = angular.copy(vm.order);
        if (newOrder && newOrder.orderGroups) {
            delete newOrder.orderGroups;
        }

        return angular.equals(origOrder, newOrder);
    }

    disableSubmit() {
        let vm = this;
        let allValid = true;

        // Make sure there actually are order groups by looking at all of the places we store their info
        let vmGroupsSize = Object.keys(vm.groups).length;
        if (
            (!vm.order || !vm.order.orderGroups || vm.order.orderGroups.length < 1) &&
            (!vm.groups || vmGroupsSize < 1)
        ) {
            return true;
        }

        if (['DRAFT', 'CANCELLED'].indexOf(vm.order.deliveryStatus) < 0) {
            return true;
        }

        if (!vm.loginService.hasPermission(['distributionorder.modify'])) {
            return true;
        }

        // check if there are order groups
        if (vm.order && vm.order.orderGroups && vm.order.orderGroups.length > 0) {
            // Ensure all order groups are valid
            for (let i = 0; i < vm.order.orderGroups.length; i++) {
                if (vm.order.orderGroups[i].OGDestinationsCount < 1) {
                    allValid = false;
                    break;
                } else if (
                    vm.order.orderGroups[i].OGSpotsCount < 1 &&
                    vm.order.orderGroups[i].OrderGroupTrafficCount < 1
                ) {
                    allValid = false;
                    break;
                }
            }
        }

        if (vm.groups && vmGroupsSize > 0) {
            // Ensure all order groups are valid
            for (let j = 0; j < vmGroupsSize; j++) {
                // vm.groups[Object.keys(vm.groups)[j]].OGDestinations.length
                if (vm.groups[Object.keys(vm.groups)[j]].OGDestinations.length < 1) {
                    allValid = false;
                    break;
                } else if (
                    vm.groups[Object.keys(vm.groups)[j]].OGSpots.length < 1 &&
                    vm.groups[Object.keys(vm.groups)[j]].OrderGroupTraffic.length < 1
                ) {
                    allValid = false;
                    break;
                }
            }
        }

        return !allValid;
    }

    openOrderDialog(order: any) {
        let vm = this;

        return vm.$mdDialog
            .show({
                controller: CopyOrderDialogController,
                controllerAs: 'vm',
                template: require('./copyOrderDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                fullscreen: true, // For small screens only
                locals: {
                    order,
                },
            })
            .then(
                function (options: any) {
                    vm._copyOrder(order, options);
                },
                function (err: any) {
                    //user cancelled the popup
                }
            );
    }

    showNotes(order: any) {
        let vm = this;

        return vm.$mdDialog
            .show({
                controller: 'ShowNotesDialogController',
                controllerAs: 'vm',
                template: require('./showNotesDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                fullscreen: true, // For small screens only
                locals: {
                    order,
                },
            })
            .then(
                function (newOrder: any) {
                    //User submitted the note
                    if (
                        vm.originalOrder.holdReasonTypeId !==
                        newOrder.holdReasonTypeId ||
                        vm.originalOrder.specialOrderTypeId !==
                        newOrder.specialOrderTypeId ||
                        vm.originalOrder.internalNotes !== newOrder.internalNotes ||
                        vm.originalOrder.requestedByUserId !==
                        newOrder.requestedByUserId ||
                        vm.originalOrder.billableFlag !== newOrder.billableFlag ||
                        vm.originalOrder.repitchFlag !== newOrder.repitchFlag
                    ) {
                        vm.$scope.orderDetailForm.$setDirty();
                    }
                },
                function (err: any) {
                    //user cancelled the popup
                }
            );
    }

    checkNote() {
        let vm = this;

        vm.hasNote =
            vm.order.holdReasonTypeId ||
            vm.order.specialOrderTypeId ||
            vm.order.internalNotes ||
            vm.order.requestedByUserId;
    }

    toggleArchive() {
        let vm = this;

        if (vm.order) {
            // This will set the flag to the opposite of what it is now.
            let confirmOrNot;

            if (!vm.order.archived) {
                let confirm = vm.$mdDialog
                    .confirm()
                    .title('Would you like to continue?')
                    .textContent(
                        "Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'."
                    )
                    .ariaLabel(
                        "Archiving an item will remove it from the default view. It will not be deleted but will be excluded in searches unless you select 'Include archives'."
                    )
                    .ok('Continue')
                    .cancel('Cancel');

                confirmOrNot = vm.$mdDialog.show(confirm);
            } else {
                confirmOrNot = vm.$q.when(true);
            }

            confirmOrNot.then(
                function () {
                    // User confirmed the archive function
                    vm.order.archived = !vm.order.archived;

                    vm.OrderResourceFactory.save(
                        { id: vm.order.id },
                        vm.order,
                        function success() {
                            vm.NotificationService.showNotificationToast(
                                'Order ' +
                                (vm.order.orderName
                                    ? vm.order.orderName
                                    : "'no name'") +
                                ' successfully ' +
                                (vm.order.archived ? 'archived' : 'unarchived')
                            );
                        },
                        function failure(err: any) {
                            vm.NotificationService.showNotificationToast(
                                'Order ' +
                                (vm.order.orderName
                                    ? vm.order.orderName
                                    : "'no name'") +
                                ' archive failed',
                                err
                            );
                        }
                    );
                },
                function () {
                    // User cancelled the archive function so don't do anything
                }
            );
        }
    }

    showNotifications() {
        let vm = this;

        return vm.$mdDialog
            .show({
                controller: EmailNotificationsDialogController,
                controllerAs: 'vm',
                template: require('./dialogs/emailNotificationsDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                fullscreen: true, // For small screens only
                locals: {
                    emails: vm.order.notifyEmailAddresses,
                },
            })
            .then(
                function (ret: any) {
                    vm.order.notifyEmailAddresses = ret.emails;

                    vm.OrderResourceFactory.save(
                        { id: vm.order.id },
                        vm.order,
                        function success(order: any) {
                            vm.NotificationService.showNotificationToast(
                                'Notifications for Order ' +
                                (vm.order.orderName
                                    ? vm.order.orderName
                                    : "'no name'") +
                                ' successfully updated.'
                            );
                        },
                        function failure(err: any) {
                            vm.NotificationService.showNotificationToast(
                                'Updating notifications for Order ' +
                                (vm.order.orderName
                                    ? vm.order.orderName
                                    : "'no name'") +
                                '  failed',
                                err
                            );
                        }
                    );
                },
                function () {
                    //user cancelled the popup and we don't care
                }
            );
    }

    recallOrder() {
        let vm = this;

        let confirm = vm.$mdDialog
            .confirm()
            .title('Recall Order?')
            .textContent(
                'The promos in this order will become immediately unavailable to any receiver via this order. If you wish to make any available again, they must be sent again in another order. Do you want to continue?'
            )
            .ariaLabel(
                'The promos in this order will become immediately unavailable to any receiver via this order. If you wish to make any available again, they must be sent again in another order. Do you want to continue?'
            )
            .ok('Yes, recall order')
            .cancel('No');

        vm.$mdDialog.show(confirm).then(
            function () {
                vm.OrderResourceFactory.recall(
                    { id: vm.order.id },
                    {},
                    function () {
                        vm.NotificationService.showNotificationToast(
                            'Successfully recalled order ' + vm.order.id
                        );
                        vm.$state.reload();
                    },
                    function () {
                        vm.NotificationService.showNotificationToast(
                            'Recall for order ' + vm.order.id + ' failed'
                        );
                    }
                );
            },
            function () {
                //canceled
            }
        );
    }

    showECN() {
        let vm = this;

        return vm.$mdDialog
            .show({
                controller: EcnDialogController,
                controllerAs: 'vm',
                template: require('./dialogs/ecnDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                fullscreen: true, // For small screens only
                locals: {
                    order: vm.order,
                },
            })
            .then(
                function () {
                    // User did something
                    if (
                        vm.originalOrder.deliverThroughEcnFlag !==
                        vm.order.deliverThroughEcnFlag ||
                        vm.originalOrder.confirmThroughEcnFlag !==
                        vm.order.confirmThroughEcnFlag
                    ) {
                        vm.$scope.orderDetailForm.$setDirty();
                    }
                },
                function () {
                    //user cancelled the popup
                }
            );
    }

    submitLaterOrder() {
        let vm = this;

        vm.$mdDialog
            .show({
                controller: SubmitLaterDialogController,
                controllerAs: 'vm',
                template: require('./dialogs/submitLaterDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                fullscreen: true, // For small screens only
                locals: {
                    order: vm.order,
                },
            })
            .then(
                function (submitLaterTime: any) {
                    // go forth and submit
                    vm.order.delayedStartTime = submitLaterTime;
                    vm.submitOrder();
                },
                function () {
                    //user cancelled the popup
                }
            );
    }

    submitOrder() {
        let vm = this;

        vm.processing = true;

        let priorityCheck = vm.$q.defer();
        let incompleteCheck = vm.$q.defer();
        let optimizeCheck = vm.$q.defer();
        let teamCompaniesCheck = vm.$q.defer();
        let digitalDestsCheck = vm.$q.defer();
        let directCadCheck = vm.$q.defer();

        // if (vm.loginService.isAdmin()) {
        //     vm.OrderResourceFactory.getAvailableCadHosts({}, function success(response: any) {
        //         vm.processing = false;
        //         if (!response.data || response.data.length < 1) {
        //             directCadCheck.resolve(true);
        //         } else {
        //             vm.$mdDialog
        //                 .show({
        //                     controller: SelectCadInstanceDialogController,
        //                     controllerAs: 'vm',
        //                     template: require('./dialogs/selectCadInstanceDialog-template.html'),
        //                     parent: angular.element(document.body),
        //                     clickOutsideToClose: false,
        //                     fullscreen: true, // For small screens only
        //                     locals: {
        //                         hosts: response.data,
        //                     },
        //                 })
        //                 .then(
        //                     function (selectedServer: any) {
        //                         vm.order.cadInstance = selectedServer;
        //                         vm.processing = true;
        //                         directCadCheck.resolve(true);
        //                     },
        //                     function () {
        //                         directCadCheck.reject(false);
        //                     }
        //                 );
        //         }
        //     });
        // } else {
            // directCadCheck.resolve(true);
        // }

        vm.order.cadInstance = '';
        vm.processing = true;
        directCadCheck.resolve(true);

        directCadCheck.promise.then(
            function () {
                // Digital Orders CANNOT be submitted without flight dates
                if (
                    vm.order.orderType === 'DIGITAL' &&
                    (!vm.order.flightStart || !vm.order.flightEnd)
                ) {
                    digitalDestsCheck.reject(false);
                    vm.$mdDialog.show(
                        vm.$mdDialog.alert({
                            title: 'Notice',
                            textContent:
                                'You cannot submit a digital order without flight dates.',
                            ok: 'OK',
                        })
                    );
                    return;
                }

                vm.OrderResourceFactory.validateDigitalDeliveries(
                    {
                        orderId: vm.order.id,
                        flightStart: vm.order.flightStart,
                        flightEnd: vm.order.flightEnd,
                    },
                    function success(response: any) {
                        vm.processing = false;
                        if (response.data.flightDateWarning) {
                            let confirm = vm.$mdDialog
                                .confirm()
                                .title('Digital Delivery Warning')
                                .textContent(
                                    'This order has deliveries to digital destinations, so Flight Start and Flight End Dates are required.'
                                )
                                .ariaLabel(
                                    'This order has deliveries to digital destinations, so Flight Start and Flight End Dates are required.'
                                )
                                .ok('Continue')
                                .cancel('Cancel');

                            vm.$mdDialog.show(confirm).then(
                                function () {
                                    vm.processing = true;
                                    digitalDestsCheck.resolve(true);
                                },
                                function () {
                                    digitalDestsCheck.reject(false);
                                }
                            );
                        } else {
                            digitalDestsCheck.resolve(true);
                        }
                    }
                );
            },
            function rejection() {
                vm.processing = false;
                digitalDestsCheck.reject(false);
            }
        );

        digitalDestsCheck.promise.then(
            function () {
                if (vm.order.deliveryPriority !== 'TWENTYFOUR_HOURS') {
                    vm.OrderResourceFactory.getPriorityCheck(
                        { id: vm.order.id },
                        function found(offlineOrTapeDests: any) {
                            if (offlineOrTapeDests.data.length > 0) {
                                vm.processing = false;
                                // There are tape or offline destinations in this "priority order". Uh oh...
                                vm.$mdDialog
                                    .show({
                                        controller: DeliveryPriorityDialogController,
                                        controllerAs: 'vm',
                                        template: require('./dialogs/deliveryPriorityDialog-template.html'),
                                        parent: angular.element(document.body),
                                        clickOutsideToClose: false,
                                        fullscreen: true, // For small screens only
                                        locals: {
                                            destinations: offlineOrTapeDests.data,
                                        },
                                    })
                                    .then(
                                        function () {
                                            vm.processing = true;
                                            priorityCheck.resolve(true);
                                        },
                                        function () {
                                            //user cancelled the popup
                                            priorityCheck.reject(false);
                                        }
                                    );
                            } else {
                                // No worrisome destinations
                                priorityCheck.resolve(true);
                            }
                        }
                    );
                } else {
                    priorityCheck.resolve(true);
                }
            },
            function rejection() {
                vm.processing = false;
                priorityCheck.reject(false);
            }
        );

        // Check for priority order
        priorityCheck.promise.then(
            function () {
                let spotlessGroups = vm._getSpotlessGroups();

                if (spotlessGroups.length > 0) {
                    vm.processing = false;
                    // There are incomplete spots we should freak out about
                    vm.$mdDialog
                        .show({
                            controller: UnavailableDestinationsDialogController,
                            controllerAs: 'vm',
                            template: require('./dialogs/unavailableDestinationsDialog-template.html'),
                            parent: angular.element(document.body),
                            clickOutsideToClose: false,
                            fullscreen: true, // For small screens only
                            locals: {
                                spotlessGroups,
                            },
                        })
                        .then(
                            function () {
                                vm.processing = true;
                                incompleteCheck.resolve(true);
                            },
                            function () {
                                //user cancelled the popup
                                incompleteCheck.reject(false);
                            }
                        );
                } else {
                    incompleteCheck.resolve(true);
                }
            },
            function rejection() {
                vm.processing = false;
                incompleteCheck.reject(false);
            }
        );

        // Check for potentially incomplete Order Groups
        incompleteCheck.promise.then(
            function () {
                if (vm.sessionData.account.allowOrderOptimization) {
                    vm.OrderResourceFactory.optimize({ id: vm.order.id }, function res(
                        optimized: any
                    ) {
                        if (
                            optimized &&
                            optimized.data &&
                            (!angular.equals({}, optimized.data.missingSpots) ||
                                !angular.equals({}, optimized.data.unnecessarySpots))
                        ) {
                            vm.processing = false;
                            // Display the optimization message if something was returned.
                            vm.$mdDialog
                                .show({
                                    controller: OptimizeDialogController,
                                    controllerAs: 'vm',
                                    template: require('./dialogs/optimizeDialog-template.html'),
                                    parent: angular.element(document.body),
                                    clickOutsideToClose: false,
                                    fullscreen: true, // For small screens only
                                    locals: {
                                        optimizedObjects: optimized.data,
                                    },
                                })
                                .then(
                                    function () {
                                        vm.processing = true;
                                        optimizeCheck.resolve(true);
                                    },
                                    function () {
                                        //user cancelled the popup
                                        optimizeCheck.reject(false);
                                    }
                                );
                        } else {
                            optimizeCheck.resolve(true);
                        }
                    });
                } else {
                    optimizeCheck.resolve(true);
                }
            },
            function rejection() {
                vm.processing = false;
                optimizeCheck.reject(false);
            }
        );

        // Order Optimization check
        optimizeCheck.promise.then(
            function () {
                let spotlessGroups = vm._getSpotlessGroups();
                if (
                    vm.sessionData.account.tcEnabled &&
                    spotlessGroups.length !== vm.order.orderGroups.length
                ) {
                    vm.OrderResourceFactory.checkTeamCompanies(
                        { id: vm.order.id },
                        function () {
                            // Rights Windows checked out, so move along
                            teamCompaniesCheck.resolve(true);
                        },
                        function failed(tcResponse: any) {
                            vm.processing = false;
                            tcResponse = tcResponse.data;

                            let isciList = '<ul>';
                            tcResponse.list = tcResponse.list
                                ? tcResponse.list.sort()
                                : [];
                            for (let ind = 0; ind < tcResponse.list.length; ind++) {
                                isciList += '<li>' + tcResponse.list[ind] + '</li>';
                            }
                            isciList += '</ul>';
                            if (tcResponse.list.length > 0) {
                                isciList =
                                    '<br /><strong>Spots:</strong><br />' + isciList;
                            }

                            let confirmOrNot;

                            let confirm = vm.$mdDialog
                                .confirm()
                                .title('Team Companies Error')
                                .htmlContent(tcResponse.message + '<br />' + isciList)
                                .ariaLabel(tcResponse.message)
                                .ok('Submit Anyway')
                                .cancel('Cancel Submit');
                            confirmOrNot = vm.$mdDialog.show(confirm);

                            confirmOrNot.then(
                                function () {
                                    vm.processing = true;
                                    teamCompaniesCheck.resolve(true);
                                },
                                function () {
                                    // User clicked "Cancel Submit"
                                    teamCompaniesCheck.reject(false);
                                }
                            );
                        }
                    );
                } else {
                    teamCompaniesCheck.resolve(true);
                }
            },
            function rejection() {
                vm.processing = false;
                teamCompaniesCheck.reject(false);
            }
        );

        // Validate Rights Windows
        // Do submit at the end
        teamCompaniesCheck.promise.then(
            function () {
                // Save before submitting the order
                vm._doSave(false, false).$promise.then(
                    function success() {
                        console.log('SUBMITTED');
                        //Do a submit
                        vm.OrderResourceFactory.submit(
                            {
                                id: vm.order.id,
                            },
                            {
                                cadInstance: vm.order.cadInstance,
                            },
                            function submitted() {
                                vm.processing = false;
                                vm.NotificationService.showNotificationToast(
                                    'Order ' +
                                    (vm.order.orderName
                                        ? vm.order.orderName
                                        : "'no name'") +
                                    ' submitted'
                                );
                                vm.$state.reload();
                            },
                            function failed(err: any) {
                                if (
                                    err.data.match(
                                        /^Validation Error: Order size limit exceeded.*/
                                    ) !== null
                                ) {
                                    // Notify users of order size overload
                                    vm.$mdDialog.show(
                                        vm.$mdDialog
                                            .alert()
                                            .parent(angular.element(document.body))
                                            .clickOutsideToClose(false)
                                            .title('Order size limit exceeded')
                                            .textContent(
                                                'This order has exceeded the limit of 4000 deliveries.  It has not been submitted. Please create separate orders.'
                                            )
                                            .ariaLabel(
                                                'This order has exceeded the limit of 4000 deliveries.  It has not been submitted. Please create separate orders.'
                                            )
                                            .ok('OK')
                                    );
                                } else {
                                    vm.NotificationService.showNotificationToast(err.data);
                                }
                            }
                        );
                    },
                    function failure(err: any) {
                        vm.processing = false;
                        vm.NotificationService.showNotificationToast(
                            'Order save for ' +
                            (vm.order.orderName
                                ? vm.order.orderName
                                : "'no name'") +
                            ' failed',
                            err.data
                        );
                    }
                );
            },
            function rejection() {
                // We decided not to submit at some point
                vm.processing = false;
            }
        );
    }

    downloadAsset(orderId: number, spotIsci: string, vastVersion: string) {
        let vm = this;

        if (vastVersion) {
            return true;
        } else {
            // Make request to get __sessionKey
            vm.$window.open(
                vm.EndPointService.spotDeliveredAssetEndPoint.replace(
                    ':id',
                    orderId
                ) +
                '?authorization=' +
                vm.loginService.getJwt(),
                '_blank'
            );
            return true;
        }
    }

    downloadTrafficAsset(orderId: number) {
        let vm = this;

        // Make request to get __sessionKey
        vm.AuthenticationResourceFactory.getSessionKey(
            {},
            function (sessionKey: any) {
                // Append __sessionKey to request to Node (instead of RE)
                vm.$window.open(
                    vm.EndPointService.downloadTrafficAssetEndPoint.replace(
                        ':id',
                        orderId
                    ) +
                    '?sessionKey=' +
                    sessionKey.data,
                    '_blank'
                );

                // NODE: Take __sessionKey and pipe request to RE
                // NODE: Open request directly into response to stream data back to browser
            },
            function () {
                // Unable to retrieve the session key, maybe the user is logged out.
            }
        );
        return true;
    }

    downloadDraftTrafficAsset(assetId: string) {
        let vm = this;

        // Make request to get __sessionKey
        vm.AuthenticationResourceFactory.getSessionKey(
            {},
            function (sessionKey: any) {
                // Append __sessionKey to request to Node (instead of RE)
                vm.$window.open(
                    vm.EndPointService.draftTrafficEndPoint +
                    '?assetId=' +
                    assetId +
                    '&sessionKey=' +
                    sessionKey.data,
                    '_blank'
                );

                // NODE: Take __sessionKey and pipe request to RE
                // NODE: Open request directly into response to stream data back to browser
            },
            function () {
                // Unable to retrieve the session key, maybe the user is logged out.
            }
        );
        return true;
    }

    importGroup() {
        let vm = this;

        let test: any = document.querySelector('#importGroupFileSelector');
        let fileInput: any = angular.element(test);

        // Using .one() allows us to not have to call .off() beforehand every time
        fileInput.one('change', function (e: any) {
            let uploadedFile = e.target.files[0];

            // Create a new Flow object that will be used to upload the file
            vm.fileToUpload = new Flow({
                target: vm.EndPointService.importGroupEndPoint,
                headers() {
                    let accountHash = JSON.parse(
                        vm.$window.localStorage[vm.authConstants.session.ACCOUNT_HASH]
                    );
                    let sessionData = JSON.parse(
                        vm.$window.sessionStorage[vm.authConstants.session.SESSION_DATA]
                    );

                    return {
                        Authorization: 'Bearer ' + accountHash[sessionData.accountId],
                    };
                },
                uploadMethod: 'POST',
                withCredentials: true,
                allowDuplicateUploads: true,
                // 100Mb chunks, should force everything to go at once, since it's limited to that max size
                // It's important to send this all at once since we're going straight to RE
                chunkSize: 100 * 1024 * 1024,
                query: {
                    orderId: vm.order.id,
                },
                testChunks: false, // This allows flow.js to make test calls to see which chunks have already been uploaded. So it can resume where it left off.
            });

            // Add the spot file to the uploader
            vm.fileToUpload.addFile(uploadedFile);

            // Handle upload events
            vm.fileToUpload.off();
            // Once the upload completes, submit the metadata
            vm.fileToUpload.on('fileError', function (file: any, message: any) {
                vm.importError = true;
                vm.$rootScope.isLoading = false;
                fileInput[0].files = null;
                fileInput[0].value = null;
                vm._missingMetadataPopup(message);
            });

            vm.fileToUpload.on('complete', function () {
                if (!vm.importError) {
                    vm.$rootScope.isLoading = false;
                    try {
                        fileInput[0].files = null;
                    } catch (err) {
                        // err contains a syntax error that only appears in Microsoft products
                    }

                    fileInput[0].value = null;
                    vm.$state.reload();
                }
            });

            // Begin the upload
            vm.fileToUpload.upload();
            fileInput[0].files = null;
            fileInput[0].value = null;
            vm.NotificationService.showNotificationToast('Group Import Started');
        });

        // Proxy the click to the real file selector because Material Design doesn't have file selectors
        fileInput[0].click();
    }

    removeGroups() {
        let vm = this;

        vm.$mdDialog
            .show({
                controller: RemoveOrderGroupDialogController,
                controllerAs: 'vm',
                template: require('./dialogs/removeOrderGroupDialog-template.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                fullscreen: true, // For small screens only
                locals: {
                    orderId: vm.order.id,
                    orderGroups: vm.order.orderGroups,
                },
            })
            .then(
                function (updatedGroups: any) {
                    // Update the orderGroups
                    vm.order.orderGroups = updatedGroups;

                    let found = false;

                    let groupIds = Object.keys(vm.groups);

                    for (let groupId in groupIds) {
                        found = false;

                        for (let i = 0; i < vm.order.orderGroups.length; i++) {
                            if (
                                parseInt(groupIds[groupId], 10) ===
                                parseInt(vm.order.orderGroups[i].id, 10)
                            ) {
                                found = true;
                                break;
                            }
                        }

                        if (!found) {
                            delete vm.groups[groupIds[groupId]];
                        }
                    }
                },
                function cancelled() {
                    // Do nothing, the user cancelled the dialog
                }
            );
    }

    exportOrder() {
        let vm = this;

        if (vm.new) {
            let confirmOrNot;

            let confirm = vm.$mdDialog
                .confirm()
                .title('Unsaved Data')
                .textContent('Save required before export')
                .ariaLabel('Save required before export')
                .ok('Save and export')
                .cancel('Cancel');
            confirmOrNot = vm.$mdDialog.show(confirm);

            confirmOrNot.then(
                function () {
                    vm.save(false, true).then(
                        function success() {
                            vm._doExport();
                        },
                        function failure(err: any) {
                            console.log(err);
                        }
                    );
                },
                function () {
                    // User cancelled the export function so don't do anything
                }
            );
        } else if (!vm.disableSave()) {
            vm.$mdDialog
                .show({
                    template:
                        '<md-dialog>' +
                        '  <md-dialog-content style="padding:24px;">' +
                        '<h2 class="md-title">Unsaved Data</h2>' +
                        '<br/>' +
                        'Save before export?</md-dialog-content>' +
                        '  <md-dialog-actions>' +
                        '    <md-button ng-click="vm.cancel()" class="md-secondary md-no-focus">' +
                        '      Cancel' +
                        '    </md-button>' +
                        '    <md-button ng-click="vm.saveAndExport()" class="md-primary">' +
                        '      Save and Export' +
                        '    </md-button>' +
                        '    <md-button ng-click="vm.onlyExport()" class="md-primary">' +
                        '      Export' +
                        '    </md-button>' +
                        '  </md-dialog-actions>' +
                        '</md-dialog>',
                    controller: saveBeforeExportDialogController,
                    controllerAs: 'vm',
                })
                .then(
                    function success(data: any) {
                        if (data) {
                            vm.save(false, true).then(
                                function success() {
                                    vm._doExport();
                                },
                                function failure(err: any) {
                                    console.log(err);
                                }
                            );
                        } else {
                            vm._doExport();
                        }
                    },
                    function failure() {
                        //user canceled dialog
                    }
                );
        } else {
            vm._doExport();
        }
    }

    endDateFilter(date: any) {
        return moment(date).isSameOrAfter(moment().startOf('day'));
    }

    setFlightEndDate() {
        let vm = this;

        vm.order.flightEnd = null;
    }

    getTraffic(group: any, index: any) {
        let vm = this;
        let loadedTraffic =
            vm.order.orderGroups[index].trafficNames &&
            vm.order.orderGroups[index].trafficNames.length ===
            group.OrderGroupTrafficCount;
        if (
            (group.OrderGroupTrafficCount > 0 ||
                (vm.order.orderGroups[index].OrderGroupTraffic &&
                    vm.order.orderGroups[index].OrderGroupTraffic.length > 0)) &&
            !loadedTraffic
        ) {
            vm.order.orderGroups[index].trafficNames = 'loading...';
            vm._updateTraffic(group.id);
        } else if (!loadedTraffic) {
            vm.order.orderGroups[index].trafficNames = [];
        }
    }

    displayOrderGroup(id: number, loadImages: boolean) {
        let vm = this;

        vm.showDetailPanel = true;
        vm.OrderResourceFactory.getOrderGroup(
            { id },
            function (group: any) {
                vm.loadingGroup = false;
                vm.groups[id] = group.data;
                vm.groups[id].name = vm.groups[id].name.replace(/&amp;/g, '&');
                if (loadImages) {
                    // load the spot thumbs
                    for (let i = 0; i < vm.groups[id].OGSpots.length; i++) {
                        if (
                            vm.groups[id].OGSpots[i].Spot &&
                            vm.groups[id].OGSpots[i].Spot.Storyboard[0] &&
                            vm.groups[id].OGSpots[i].Spot.Storyboard[0].Asset
                        ) {
                            // We only ever retrieve one thumbnail, so 0 is okay here
                            vm._getThumbnail(
                                vm.groups[id].OGSpots[i].Spot.isci,
                                vm.groups[id].OGSpots[i].Spot.Storyboard[0].Asset
                                    .AssetContent.contentUuid
                            );
                        }
                    }
                    // load the destination statuses
                    for (let j = 0; j < vm.groups[id].OGDestinations.length; j++) {
                        if (vm.groups[id].OGDestinations[j].Destination) {
                            vm._getDestinationStatus(
                                vm.groups[id].OGDestinations[j].Destination
                                    .distributionType,
                                vm.groups[id].OGDestinations[j].Destination
                                    .ReceiverAccount.id,
                                vm.groups[id].OGDestinations[j].Destination.id
                            );
                        }
                    }
                }
                vm.selectedGroup = vm.groups[id];
            },
            function (error: any) {
                vm.loadingGroup = false;
                vm.NotificationService.showNotificationToast(
                    'Order Group failed to load',
                    error.data
                );
            }
        );
    }

    openVastData(contentUuid: string, filePath: string, vastUrl: string) {
        let vm = this;

        return vm.$mdDialog.show({
            controller: VastXmlDialogController,
            controllerAs: 'vm',
            template: require('./dialogs/vastXmlDialog-template.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: false,
            fullscreen: true, // For small screens only
            locals: {
                filePath,
                contentUuid,
                vastUrl,
            },
        });
    }

    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them
    _getSpotlessGroups() {
        let vm = this;

        return vm.order.orderGroups.filter(function (group: any) {
            return (
                group.OGSpotsCount < 1 || (group.OGSpots && group.OGSpots.length === 0)
            );
        });
    }

    _updateMetadata() {
        let vm = this;

        vm.order.agencyId =
            vm.order.Agency && vm.order.Agency.id ? vm.order.Agency.id : null;
        vm.order.advertiserId =
            vm.order.Advertiser && vm.order.Advertiser.id
                ? vm.order.Advertiser.id
                : null;
        vm.order.brandId =
            vm.order.Brand && vm.order.Brand.id ? vm.order.Brand.id : null;
        vm.order.campaignId =
            vm.order.Campaign && vm.order.Campaign.id ? vm.order.Campaign.id : null;
    }

    _doSave(loadNew: boolean, notify: boolean) {
        let vm = this;

        notify = notify === undefined ? true : notify;
        vm._updateMetadata();

        return vm.OrderResourceFactory.save(
            {},
            vm.order,
            function success(response: any) {
                vm.order.id = response.id;

                if (notify) {
                    vm.NotificationService.showNotificationToast(
                        'Order ' +
                        (vm.order.orderName ? vm.order.orderName : "'no name'") +
                        ' successfully saved'
                    );
                }
                vm.$scope.orderDetailForm.$setPristine();
                vm.$rootScope.$broadcast(vm.EventConstants.orderDetail.save);
                if (loadNew) {
                    vm._loadNewOrder(false);
                } else if (vm.$stateParams.id === undefined || vm.$stateParams.id === '') {
                    vm.$state.go('orderDetail', {
                        id: vm.order.id,
                    });
                } else {
                    vm.$state.reload();
                }
            },
            function failure(err: any) {
                vm.NotificationService.showNotificationToast(
                    'Order save for ' +
                    (vm.order.orderName ? vm.order.orderName : "'no name'") +
                    ' failed',
                    err.data
                );
                console.log(err);
            }
        );
    }

    _getThumbnail(isci: string, uuid: string) {
        let vm = this;

        if (vm.spotThumbs && !vm.spotThumbs[isci]) {
            vm.AssetResourceFactory.getImageAsset(
                { uuid },
                {},
                function success(asset: any) {
                    let blob = new Blob([asset.data], {
                        type: asset.config['Content-Type'],
                    });
                    vm.spotThumbs[isci] = URL.createObjectURL(blob);
                },
                function failure() {
                    // Unable to retrieve the image asset for the thumbnail
                }
            );
        }
    };

    _getDestinationStatus(distributionType: string, receiverId: number, destinationId: number) {
        let vm = this;

        if (vm.destinationStatuses && !vm.destinationStatuses[destinationId]) {
            vm.DestinationResourceFactory.getStatus(
                {
                    id: destinationId,
                    distributionType,
                    receiverId,
                },
                function (status: any) {
                    vm.destinationStatuses[destinationId] =
                        status.data === null || status.data === undefined
                            ? 'false'
                            : status.data.toString();
                },
                function failure() { }
            );
        }
    };

    _loadOrderGroups() {
        let vm = this;

        vm.loadingGroup = true;
        return vm.OrderResourceFactory.getOrderGroups(
            { id: vm.order.id },
            function success(orderGroups: any) {
                vm.order.orderGroups = orderGroups.data.orderGroups;
                for (let i = 0; i < vm.order.orderGroups.length; i++) {
                    vm.order.orderGroups[i].name = vm.order.orderGroups[i].name.replace(
                        /&amp;/g,
                        '&'
                    );
                    vm.order.orderGroups[i].trafficNames = 'loading...';
                    vm.order.orderGroups[i].loadedTraffic = false;
                }
                vm.loadingGroup = false;
                vm.orderGroupPromise.resolve();
            },
            function failure(err: any) {
                vm.loadingGroup = false;
                vm.NotificationService.showNotificationToast('Failed to load order', err);
                vm.orderGroupPromise.resolve();
                console.log(err);
            }
        );
    };

    _loadEnumData() {
        let vm = this;

        // Return them in a promise, so we can wait for them to finish before moving on if we want to
        return vm.$q.all([
            vm.EnumService.getEnum('DistributionOrder', 'orderType').then(
                function (orderTypes: any) {
                    if (orderTypes && orderTypes.length > 0 && vm.sessionData.account) {
                        // only add in allowed order types for the logged-in account
                        vm.orderTypes = [];
                        for (let i = 0; i < orderTypes.length; i++) {
                            switch (orderTypes[i].value) {
                                case 'RADIO':
                                    if (vm.sessionData.account.allowRadio) {
                                        vm.orderTypes.push(orderTypes[i]);
                                    }
                                    break;
                                case 'TV':
                                    if (!vm.sessionData.account.disallowTv) {
                                        vm.orderTypes.push(orderTypes[i]);
                                    }
                                    break;
                                case 'PROMO':
                                    if (vm.sessionData.account.allowPromo) {
                                        vm.orderTypes.push(orderTypes[i]);
                                    }
                                    break;
                                case 'DIGITAL':
                                    vm.orderTypes.push(orderTypes[i]);
                                    break;
                            }
                        }
                    }
                },
                function () {
                    vm.orderTypes = [];
                }
            ),
            vm.EnumService.getEnum('DistributionOrder', 'deliveryPriority').then(
                function (priorities: any) {
                    vm.deliveryPriorities = priorities;
                    if (
                        vm.deliveryPriorities &&
                        vm.deliveryPriorities.length > 0 &&
                        !vm.sessionData.account.allow30MinSLAFlag
                    ) {
                        // remove 30 min SLA if not allowed in this account
                        for (let i = 0; i < vm.deliveryPriorities.length; i++) {
                            if (vm.deliveryPriorities[i].value === 'THIRTY_MINUTES') {
                                vm.deliveryPriorities.splice(i, 1);
                                break;
                            }
                        }
                    }
                },
                function () {
                    vm.deliveryPriorities = [];
                }
            ),
        ]);
    }

    async _loadData() {
        let vm = this;

        // Load the orderType enum in the background
        vm.EnumService.getEnum('DistributionOrder', 'orderType').then(
            function success(orderTypes: any) {
                if (orderTypes && orderTypes.length > 0 && vm.sessionData.account) {
                    // only add in allowed order types for the logged-in account
                    vm.orderTypes = [];
                    for (let i = 0; i < orderTypes.length; i++) {
                        switch (orderTypes[i].value) {
                            case 'RADIO':
                                if (vm.sessionData.account.allowRadio) {
                                    vm.orderTypes.push(orderTypes[i]);
                                }
                                break;
                            case 'TV':
                                if (!vm.sessionData.account.disallowTv) {
                                    vm.orderTypes.push(orderTypes[i]);
                                }
                                break;
                            case 'PROMO':
                                if (vm.sessionData.account.allowPromo) {
                                    vm.orderTypes.push(orderTypes[i]);
                                }
                                break;
                            case 'DIGITAL':
                                vm.orderTypes.push(orderTypes[i]);
                                break;
                        }
                    }
                }
            },
            function failure() {
                vm.orderTypes = [];
            }
        );
        // Load the deliveryPriority enum in the background
        vm.EnumService.getEnum('DistributionOrder', 'deliveryPriority').then(
            function (priorities: any) {
                vm.deliveryPriorities = priorities;
                if (
                    vm.deliveryPriorities &&
                    vm.deliveryPriorities.length > 0 &&
                    !vm.sessionData.account.allow30MinSLAFlag
                ) {
                    // remove 30 min SLA if not allowed in this account
                    for (let i = 0; i < vm.deliveryPriorities.length; i++) {
                        if (vm.deliveryPriorities[i].value === 'THIRTY_MINUTES') {
                            vm.deliveryPriorities.splice(i, 1);
                            break;
                        }
                    }
                }
            },
            function () {
                vm.deliveryPriorities = [];
            }
        );

        // If Promo-only, default it
        if (
            vm.new ||
            ((vm.$stateParams.id === undefined || vm.$stateParams.id === '') && (!vm.order || !vm.order.id))
        ) {
            vm._loadNewOrder(true);
        } else {
            vm.OrderResourceFactory.get(
                {
                    id: vm.$stateParams.id ? vm.$stateParams.id : vm.order.id,
                },
                function (order: any) {
                    vm.order = order;
                    vm.order.statusBar = vm.statusService.readOrderStatus(vm.order);
                    // Convert flight dates to actual dates, so the datepickers will handle them correctly on load
                    vm.order.flightStart = (vm.order.flightStart) ? new Date(vm.order.flightStart) : null;
                    vm.order.flightEnd = (vm.order.flightEnd) ? new Date(vm.order.flightEnd) : null;

                    vm._loadOrderGroups().$promise.then(function () {
                        vm.order.Agency = vm.order.Agency ? vm.order.Agency : undefined;
                        vm.order.Advertiser = vm.order.Advertiser ? vm.order.Advertiser : undefined;
                        vm.order.Brand = vm.order.Brand ? vm.order.Brand : undefined;
                        vm.order.Campaign = vm.order.Campaign ? vm.order.Campaign : undefined;
                        vm.checkNote();
                        vm.originalOrder = angular.copy(vm.order);
                    });
                },
                function (error: any) {
                    vm.NotificationService.showNotificationToast(
                        'Order failed to load, connection to server may have been interrupted.  Please try logging out and back in.',
                        error.data
                    );
                }
            );
        }
    }

    _loadNewOrder(blankOrder: any) {
        let vm = this;

        vm.new = true;
        if (blankOrder) {
            let defaultType = !vm.sessionData.account.disallowTv
                ? 'TV'
                : vm.sessionData.account.allowPromo
                    ? 'PROMO'
                    : 'RADIO';

            vm.order = {
                /*•	If account allows promo, disallows TV, and does not allow Radio, then default to PROMO
                 •	If account does not allow promo, disallows TV, and allows Radio, then default to RADIO
                 •	If account does not allow promo and does not allow Radio, default to TV*/
                orderType: defaultType,
                deliveryPriority: 'TWENTYFOUR_HOURS',
                deliveryStatus: 'DRAFT',
            };
        } else {
            vm.order = {
                Agency: vm.order.Agency,
                Advertiser: vm.order.Advertiser,
                Brand: vm.order.Brand,
                Campaign: vm.order.Campaign,
                deliveryPriority: vm.order.deliveryPriority,
                deliveryStatus: 'DRAFT',
            };
        }
        vm.order.statusBar = vm.statusService.readOrderStatus(vm.order);
        vm.order.createdByUserId = vm.sessionData.id;
    }

    _copyOrder(order: any, options: any) {
        let vm = this;

        vm.copying = true;
        vm.OrderResourceFactory.copyOrder(
            {
                id: order.id,
                brandId: order.brandId,
                advertiserId: order.advertiserId,
                poNumber: order.poNumber,
                notifyEmailAddresses: options.copyEmailNotifications
                    ? order.notifyEmailAddresses
                    : null,
                agencyId: order.agencyId,
                options,
            },
            function (newOrder: any) {
                vm.OrderResourceFactory.get(
                    { id: newOrder.data },
                    function success(hydratedOrder: any) {
                        hydratedOrder.notifyEmailAddresses = options.copyEmailNotifications
                            ? vm.order.notifyEmailAddresses
                            : '';
                        hydratedOrder.confirmThroughEcnFlag = false;
                        hydratedOrder.deliverThroughEcnFlag = false;
                        hydratedOrder.internalNotes = vm.order.internalNotes;
                        hydratedOrder.orderHoldReasonType =
                            vm.order.orderHoldReasonType;
                        hydratedOrder.specialOrderTypeId = vm.order.specialOrderTypeId;
                        hydratedOrder.holdReasonTypeId = vm.order.holdReasonTypeId;
                        hydratedOrder.repitchFlag = vm.order.repitchFlag
                            ? vm.order.repitchFlag
                            : false;

                        //This code appends the original submitter to the copied order. Jira  COM-17515. 
                        // hydratedOrder.submitterEmail = vm.order.submitterEmail;
                        // hydratedOrder.effectiveSubmitTime =vm.order.effectiveSubmitTime;
                        vm.OrderResourceFactory.save(
                            { id: hydratedOrder.id },
                            hydratedOrder,
                            function success(finalOrder: any) {
                                vm.NotificationService.showNotificationToast(
                                    'Order ' + order.id + ' successfully copied'
                                );
                                vm.copying = false;
                                vm.$state.go(
                                    'orderDetail',
                                    { id: parseInt(finalOrder.id, 10) },
                                    { inherit: false }
                                );
                            },
                            function failure(err: any) {
                                console.log(err);
                            }
                        );
                    },
                    function failure(err: any) {
                        console.log(err);
                    }
                );
            },
            function (failure: any) {
                vm.NotificationService.showNotificationToast('Order copy failed', failure);
            }
        );
    }

    _missingMetadataPopup(message: any) {
        let vm = this;

        vm.$mdDialog.show({
            controller: OrderImportErrorDialogController,
            controllerAs: 'vm',
            template: require('./dialogs/orderImportErrorDialog-template.html'),
            parent: angular.element(document.body),
            clickOutsideToClose: false,
            fullscreen: true, // For small screens only
            locals: {
                message,
            },
        });
    }

    _doExport() {
        let vm = this;

        vm.OrderResourceFactory.exportOrder(
            { id: vm.order.id },
            {},
            function (file: any) {
                if (Bowser.msie || Bowser.msedge) {
                    vm.$window.navigator.msSaveBlob(
                        file.data.data,
                        'order ' + vm.order.id + '.xls'
                    );
                } else {
                    let url = URL.createObjectURL(file.data.data);
                    let downloadLink: any = document.createElement('A');
                    downloadLink.href = url;
                    downloadLink.download = 'order ' + vm.order.id + '.xls';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }
                URL.revokeObjectURL(file.data.data);
            },
            function failure(err: any) {
                console.log(err);
            }
        );
    }

    _updateTraffic(allIdsForTraffic: any) {
        let vm = this;

        vm.OrderResourceFactory.getOrderGroupTraffic(
            { idArray: allIdsForTraffic },
            function (trafficObjects: any) {
                for (let i = 0; i < vm.order.orderGroups.length; i++) {
                    let order = vm.order.orderGroups[i];

                    if (allIdsForTraffic === order.id) {
                        vm.order.orderGroups[i].trafficNames = [];
                    }
                    for (let j = 0; j < trafficObjects.data.length; j++) {
                        let traffic = trafficObjects.data[j];

                        if (traffic.id == order.id && allIdsForTraffic === order.id) {
                            vm.order.orderGroups[i].trafficNames.push(
                                traffic.trafficNames
                            );
                        }
                    }
                }
            }
        );
    }

    _refreshGroups(loadOrderGroupRequests: any) {
        let vm = this;

        vm.$q.all(loadOrderGroupRequests).then(function (updatedGroups: any) {
            updatedGroups = updatedGroups.map(function (group: any) {
                return group.data;
            });

            updatedGroups.forEach((updatedGroup: any) => {
                let updatedGroupId = updatedGroup.id;

                for (let i = 0; i < vm.order.orderGroups.length; i++) {
                    let orderGroup = vm.order.orderGroups[i];
                    vm.order.orderGroups[i].loadedTraffic = false;

                    if (orderGroup.id == updatedGroupId) {
                        vm.order.orderGroups[i] = updatedGroup;
                        vm.order.orderGroups[i].OrderGroupTrafficCount =
                            updatedGroup.OrderGroupTraffic.length;
                        vm.order.orderGroups[i].name = vm.groups[updatedGroupId].name;
                        vm.order.orderGroups[i].trafficNames = 'loading...';
                    }
                    vm.order.orderGroups[i].name = vm.order.orderGroups[i].name.replace(
                        /&amp;/g,
                        '&'
                    );
                }
            });
        });
    }
}
